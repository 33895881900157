@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@font-face {
  font-family: "Tiposka";
  src: local("Tiposka"),
    url("./assets/fonts/Tiposka.ttf") format("opentype");
  font-weight: normal;
}

*{
  padding: 0px;
  margin: 0px;
}

body, html {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  background-color: #FEF3E9;
}
